import type { ListPage, MenuItem } from '@madxnl/mrrabbit'

export const config: MenuItem[] = [
  {
    name: 'Enterprises',
    listPage: {
      identifier: 'id',
      type: 'Enterprise',
      model: 'Enterprise',
      fields: {
        id: true,
        enterpriseDisplayName: true,
      },
      editPage: {
        fields: {
          id: { type: 'text', readOnly: true },
          enterpriseDisplayName: { type: 'text' },
        },
      },
    } as ListPage<any>,
  },
  {
    name: 'Devices',
    listPage: {
      identifier: 'id',
      type: 'Device',
      model: 'Device',
      fields: {
        id: true,
      },
      editPage: {
        fields: {
          id: { type: 'text', readOnly: true },
        },
      },
    } as ListPage<any>,
  },
]
